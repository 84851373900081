import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';

import discord from './modules/discord';
import cart from './modules/cart';
// Deprecated
// import paypal from './modules/paypal'
// import stripe from './modules/stripe';
import clover from './modules/clover';
// import square from './modules/square';
// import payment from './modules/paymentCloud'
import coinbase from './modules/coinbase';
import store from './modules/store';
import customize from './modules/customize';
import cookie from './modules/cookie';
import admin from './modules/admin';
import emotes from './modules/emotes';
import captcha from './modules/captcha';
import report from './modules/report';
import battleLog from './modules/battleLog';
import customPet from './modules/customPet';

Vue.use(Vuex);

const storage = new VuexPersist({
	key: 'owo',
	storage: window.localStorage,
	reducer: (state) => ({
		cart: state.cart,
		cookie: state.cookie,
	}),
});

export default new Vuex.Store({
	plugins: [storage.plugin],
	modules: {
		clover,
		coinbase,
		discord,
		cart,
		store,
		customize,
		admin,
		cookie,
		emotes,
		captcha,
		report,
		battleLog,
		customPet,
	},
});
