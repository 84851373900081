import axios from 'axios';
import Vue from 'vue';

const getDefaultState = () => {
	return {
		user: {
			id: null,
			username: null,
			discriminator: null,
			fullName: null,
			avatar: null,
			avatarUrlPng: null,
			avatarUrlGif: null,
			captcha: {
				active: null,
				uuid: null,
			},
		},
		userFetched: false,
	};
};

const state = getDefaultState();

const getters = {
	user: (state) => state.user,
	userFetched: (state) => state.userFetched,
};

const actions = {
	getUser: async ({ commit, dispatch }) => {
		try {
			let res = await axios.get(process.env.VUE_APP_BACKEND + '/api/auth', {
				withCredentials: true,
			});
			const user = res.data;
			await commit('setUser', user);
			await commit('fetched');
			return user;
		} catch (err) {
			await commit('fetched');
			throw err;
		}
	},
	logout: async ({ commit }) => {
		await axios.get(process.env.VUE_APP_BACKEND + '/api/auth/logout', {
			withCredentials: true,
		});
		await commit('clearUser');
	},
	setAvatarUrl: async ({ commit }, url) => {
		await commit('setAvatarUrl', url);
	},
	getUserById: async (_, id) => {
		let res = await axios.get(
			process.env.VUE_APP_BACKEND + '/api/util/get-user/' + id,
			{
				withCredentials: true,
			}
		);
		return res.data;
	},
	getGuildById: async (_, id) => {
		let res = await axios.get(
			process.env.VUE_APP_BACKEND + '/api/util/get-guild/' + id,
			{
				withCredentials: true,
			}
		);
		return res.data;
	},
	optOutMessageIntent: async () => {
		return axios.post(
			`${process.env.VUE_APP_BACKEND}/api/util/opt-out-message`,
			{},
			{ withCredentials: true }
		);
	},
	getStatus: async () => {
		const result = await axios.get(process.env.VUE_APP_BACKEND + '/api/status');
		return result.data;
	},
};

const mutations = {
	setUser: (state, userInfo) => {
		state.user = userInfo;
		Vue.set(state, 'user', userInfo);
		Vue.set(state.user, 'id', userInfo.discordId);
		Vue.set(
			state.user,
			'fullName',
			userInfo.globalName || `${userInfo.username}#${userInfo.discriminator}`
		);
		Vue.set(
			state.user,
			'avatarUrlPng',
			`https://cdn.discordapp.com/avatars/${userInfo.discordId}/${userInfo.avatar}.png`
		);
		Vue.set(
			state.user,
			'avatarUrlGif',
			`https://cdn.discordapp.com/avatars/${userInfo.discordId}/${userInfo.avatar}.gif`
		);
	},
	clearUser: (state) => {
		Object.assign(state, getDefaultState());
	},
	setAvatarUrl: (state, url) => {
		Vue.set(state.user, 'avatarUrl', url);
	},
	fetched: (state) => {
		state.userFetched = true;
	},
};

export default { state, getters, actions, mutations };
