<template>
	<div class="home-body">
		<HomeBodyPanel :title="hunt.title" :desc="hunt.desc">
			<div class="img-container">
				<img
					src="@/assets/home/hunt.gif"
					class="example-img"
					:width="isSmall ? 300 : 500"
				/>
				<img src="@/assets/home/egem.png" class="egem" height="70" />
				<img src="@/assets/home/fgem.gif" class="fgem" height="70" />
				<img src="@/assets/home/lgem.gif" class="lgem" height="70" />
			</div>
		</HomeBodyPanel>
		<HomeBodyPanel :title="battle.title" :desc="battle.desc" flip>
			<div class="img-container">
				<img
					src="@/assets/home/battle.gif"
					class="example-img"
					:width="isSmall ? 300 : 500"
				/>
				<img src="@/assets/home/flaxe.png" class="flaxe" width="90" />
				<img src="@/assets/home/eorb.png" class="eorb" height="90" />
				<img
					src="@/assets/home/mvampstaff.png"
					class="mvampstaff"
					height="90"
				/>
				<img
					src="@/assets/home/rhealstaff.png"
					class="rhealstaff"
					height="90"
				/>
			</div>
		</HomeBodyPanel>
		<HomeBodyPanel :title="leaderboards.title" :desc="leaderboards.desc" flip>
			<div class="img-container leaderboard-image">
				<img src="@/assets/home/epic.png" class="epic" width="100" />
				<img src="@/assets/home/mythical.png" class="mythical" width="100" />
				<img src="@/assets/home/legendary.gif" class="legendary" width="100" />
				<img src="@/assets/home/fabled.gif" class="fabled" width="100" />
			</div>
		</HomeBodyPanel>
		<HomeBodyPanel :title="lootboxes.title" :desc="lootboxes.desc">
			<div class="img-container lootbox-image">
				<img src="@/assets/home/crate.png" class="crate" width="100" />
				<img src="@/assets/home/fbox.gif" class="fbox" width="100" />
				<img src="@/assets/home/fgem.gif" class="box-open-gem" width="110" />
				<img src="@/assets/home/boxopen.gif" class="box-open" width="130" />
			</div>
		</HomeBodyPanel>
		<HomeBodyPanel :title="xp.title" :desc="xp.desc" flip>
			<div class="img-container">
				<img
					src="@/assets/home/levelup.png"
					class="example-img"
					:width="isSmall ? 300 : 500"
				/>
			</div>
		</HomeBodyPanel>
		<HomeBodyPanel :title="meme.title" :desc="meme.desc">
			<div class="img-container">
				<img
					src="@/assets/home/memes.gif"
					class="example-img"
					:width="isSmall ? 300 : 500"
				/>
				<img src="@/assets/home/joy.svg" class="joy" width="100" />
			</div>
		</HomeBodyPanel>
	</div>
</template>

<script>
import HomeBodyPanel from '@/views/home/components/HomeBodyPanel';

export default {
	name: 'HomeBody',
	components: {
		HomeBodyPanel,
	},
	data: () => ({
		hunt: {
			title: 'Hunt over hundreds of different animals',
			desc: 'Use the hunt command to find animals with different rarities. Expand your collection and brag about how much bigger your zoo is compared to theirs.',
		},
		battle: {
			title: 'Battle other players',
			desc: 'Use your animals to establish dominance. Defeat other players to level up your team. Use different weapons and animals to match your playstyle.',
		},
		leaderboards: {
			title: 'Climb the leaderboards',
			desc: 'Leaderboards for you zoo, team, luck, xp, etc. We have it all! Brag about how much better you are in OwO Bot 😎',
		},
		lootboxes: {
			title: 'Lootboxes.',
			desc: "Open different types of lootboxes and weapon crates! This game is NOT pay-to-win it's all about luck!",
		},
		xp: {
			title: 'Level yourself up!',
			desc: 'Gain experience by talking with friends on discord. Level up to gain rewards and show how active you are within the community.',
		},
		meme: {
			title: 'Make some memes',
			desc: 'Use your image generation to make some cool images and gifs. You can even automatically add them to your server emojis!',
		},
	}),
	computed: {
		isSmall() {
			return this.$vuetify.breakpoint.width < 540;
		},
	},
};
</script>

<style scoped>
.home-body {
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.example-img {
	border: 3px solid var(--v-secondary-base);
	border-radius: 8px;
}

.img-container {
	position: relative;
	padding: 30px;
}

.egem {
	position: absolute;
	top: 13px;
	left: 50px;
}

.fgem {
	position: absolute;
	bottom: 15px;
	right: 10px;
	transform: rotate(10deg);
}

.lgem {
	position: absolute;
	top: 16px;
	left: 0;
	transform: rotate(-17deg);
}

.flaxe {
	position: absolute;
	bottom: 120px;
	left: -30px;
	transform: rotate(-17deg);
}

.eorb {
	position: absolute;
	bottom: 120px;
	left: 50px;
	transform: rotate(10deg);
}

.mvampstaff {
	position: absolute;
	top: 16px;
	right: 0px;
	transform: rotate(17deg);
}

.rhealstaff {
	position: absolute;
	top: 16px;
	right: 90px;
	transform: rotate(-17deg);
}

.epic {
	position: absolute;
	top: 30px;
	left: 30px;
}

.mythical {
	position: absolute;
	top: 30px;
	right: 30px;
}

.legendary {
	position: absolute;
	bottom: 30px;
	left: 30px;
}

.fabled {
	position: absolute;
	bottom: 30px;
	right: 30px;
}

.leaderboard-image {
	width: 300px;
	height: 300px;
	border: 3px solid var(--v-secondary-base);
	border-radius: 8px;
}

.lootbox-image {
	width: 350px;
	height: 300px;
	border: 3px solid var(--v-secondary-base);
	border-radius: 8px;
}

.fbox {
	position: absolute;
	bottom: 30px;
	right: 35px;
	transform: rotate(17deg);
}

.box-open {
	position: absolute;
	bottom: 50px;
	left: 106px;
}

.crate {
	position: absolute;
	bottom: 30px;
	left: 35px;
	transform: rotate(-17deg);
}

.box-open-gem {
	position: absolute;
	top: 38px;
	left: 116px;
}

.joy {
	position: absolute;
	top: 0px;
	right: 0px;
	transform: rotate(17deg);
}
</style>
