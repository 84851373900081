import { render, staticRenderFns } from "./ParticleConfetti.vue?vue&type=template&id=649bcd73&scoped=true"
import script from "./ParticleConfetti.vue?vue&type=script&lang=js"
export * from "./ParticleConfetti.vue?vue&type=script&lang=js"
import style0 from "./ParticleConfetti.vue?vue&type=style&index=0&id=649bcd73&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "649bcd73",
  null
  
)

export default component.exports