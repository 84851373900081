<template>
	<v-footer class="pa-0 footer-height">
		<v-card flat height="100%" width="100%" class="footer pl-3">
			<div class="footer-links">
				<div class="category-links">
					<v-btn
						v-for="link in links"
						:key="link.text"
						:ripple="{ class: 'secondary--text' }"
						small
						text
						class="mx-4 link-button contact-text link"
						:to="link.to"
					>
						<span>{{ link.text }}</span>
					</v-btn>
				</div>
				<div class="category">
					<span class="category-text mb-3"> Contact Us </span>
					<v-btn
						:ripple="{ class: 'secondary--text' }"
						small
						text
						class="contact-text link"
						href="https://discord.com/invite/owobot"
					>
						<v-img
							class="contact-icon"
							contain
							height="22"
							width="22"
							:src="require('@/assets/discord-logo.svg')"
						></v-img>
						<div class="link">Join Our Discord</div>
					</v-btn>
					<v-btn
						:ripple="{ class: 'secondary--text' }"
						small
						text
						class="contact-text link"
						href="mailto:owobotdev@gmail.com"
					>
						<v-icon size="22" class="contact-icon">mdi-email-outline</v-icon>
						<div class="link">owobotdev@gmail.com</div>
					</v-btn>
					<v-btn small text class="contact-text link">
						<v-icon size="22" class="contact-icon">mdi-phone-outline</v-icon>
						<div class="link">+1 (909) 451 9759</div>
					</v-btn>
					<v-btn small text class="contact-text link">
						<v-icon size="22" class="contact-icon"
							>mdi-mailbox-up-outline</v-icon
						>
						<div class="address-text link">
							555 Anton Boulevard<br />
							Suites 150<br />
							Costa Mesa CA, 92626
						</div>
					</v-btn>
				</div>
			</div>
			<span class="caption">&#169; 2022 OwO Bot. All rights reserved.</span>
		</v-card>
		<CookieSnackbar />
	</v-footer>
</template>

<script>
import routes from '@/router/routes';
import CookieSnackbar from '@/components/CookieSnackbar';

export default {
	name: 'TheFooter',
	components: { CookieSnackbar },
	data: () => ({
		links: [
			{
				text: 'Home',
				to: routes.HOME,
			},
			{
				text: 'Help',
				to: routes.HELP,
			},
			{
				text: 'Store',
				to: routes.STORE,
			},
			{
				text: 'Terms of Service',
				to: routes.TERMS_OF_SERVICE,
			},
			{
				text: 'Privacy Policy',
				to: routes.PRIVACY_POLICY,
			},
			{
				text: 'Return Policy',
				to: routes.RETURN_POLICY,
			},
			{
				text: 'Disclaimer',
				to: routes.DISCLAIMER,
			},
		],
	}),
};
</script>
<style scoped>
.footer-height {
	height: min-content;
}

.footer {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.footer-links {
	height: 250px;
	width: 100%;
	align-self: flex-end;
	display: flex;
	justify-content: space-between;
	overflow-x: hidden;
}

.caption {
	color: gray;
	align-self: flex-start;
}

.category {
	padding-top: 30px;
	display: flex;
	flex-direction: column;
	align-self: flex-start;
}

.category-links {
	padding-top: 30px;
	display: flex;
	flex-direction: row;
	align-self: flex-start;
	flex-wrap: wrap;
}

.link-button {
	flex: 40%;
}

.category-text {
	font-size: 1.2rem;
}

.link {
	font-size: 0.9rem;
	margin-bottom: 4px;
	width: min-content;
	white-space: nowrap;
	text-transform: none;
}

.spacer {
	width: 200px;
}

.contact-text {
	display: flex;
}

.contact-icon {
	max-width: 22px;
	margin-right: 10px;
}

.address-text {
	margin-top: 38px;
}
</style>
