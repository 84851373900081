<template>
	<div class="form-row">
		<v-card-title>Footer</v-card-title>
		<v-text-field
			class="pt-3 pr-3"
			v-model="footer"
			label="Footer text"
			counter="256"
			dense
			outlined
			:rules="rules"
		></v-text-field>
	</div>
</template>

<script>
const field = 'footer';

export default {
	name: 'FormFooter',
	data: () => ({
		rules: [
			(v) => !v || v.length <= 256 || 'Footer must be under 256 characters',
			(v) => !v || (v.match(/\n/g)?.length || 0) < 6 || 'Maximum of 6 lines',
			(v) => !v || !v.match(/<@!?&?\d+>/gi) || "You can't tag a user or role",
			(v) =>
				!v ||
				!v.match(
					/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/gi
				) ||
				"You can't include a link",
			(v) =>
				!v || !v.match(/discord\.gg/gi) || "You can't include an invite link",
		],
	}),
	computed: {
		footer: {
			get() {
				return this.$store.getters.selectedForm[field];
			},
			set(value) {
				this.$store.dispatch('updateSelectedForm', { field, value });
			},
		},
	},
};
</script>

<style scoped>
.form-row {
	display: flex;
	align-items: flex-start;
}
</style>
