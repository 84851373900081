import axios from 'axios';

const actions = {
	createCloverTransaction: async ({ rootGetters }, giftUserId) => {
		const items = [];
		const cart = rootGetters.cart;
		const total = rootGetters.totalCost;
		for (let id in cart) {
			const item = cart[id];
			items.push({ id, count: item.count });
		}
		let res = await axios.post(
			process.env.VUE_APP_BACKEND + '/api/clover/transaction',
			{ items, total, giftUserId },
			{ withCredentials: true }
		);
		return res.data;
	},
	captureCloverTransaction: async (vuex, referenceID) => {
		let res = await axios.post(
			process.env.VUE_APP_BACKEND + '/api/clover/capture',
			{
				data: { referenceID },
			},
			{
				withCredentials: true,
			}
		);
		return res.data;
	},
};

export default { actions };
