<template>
	<div class="home">
		<HomeHeader />
		<HomeBody />
	</div>
</template>

<script>
import HomeHeader from '@/views/home/components/HomeHeader';
import HomeBody from '@/views/home/components/HomeBody';

export default {
	name: 'HomePage',
	components: {
		HomeHeader,
		HomeBody,
	},
	created() {
		const route = this.$store.getters.preLoginUrl;
		this.$store.dispatch('setPreLoginUrl', '/');
		if (route && route != '/') {
			this.$router.push(route);
		}
	},
};
</script>

<style scoped>
.home {
	width: 100%;
	display: flex;
	flex-direction: column;
}
</style>
