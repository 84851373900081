<template>
	<div class="form-row">
		<v-card-title>Side Image</v-card-title>
		<v-text-field
			:rules="rules"
			class="pt-3 pr-3"
			v-model="sideImg"
			label="Image URL"
			counter="256"
			dense
			outlined
		></v-text-field>
	</div>
</template>

<script>
const field = 'sideImg';

export default {
	name: 'FormSideImage',
	data: () => ({
		rules: [
			(v) =>
				!v ||
				/^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)$/.test(
					v
				) ||
				'Must be a valid URL',
			(v) => !v || v.length <= 256 || 'URL must be under 256 characters',
		],
	}),
	computed: {
		sideImg: {
			get() {
				return this.$store.getters.selectedForm[field];
			},
			set(value) {
				this.$store.dispatch('updateSelectedForm', { field, value });
			},
		},
	},
};
</script>

<style scoped>
.form-row {
	display: flex;
	align-items: flex-start;
}
</style>
