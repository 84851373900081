<template>
	<v-app-bar app color="primary" hide-on-scroll>
		<div class="d-flex align-center navbar-height noselect" @click="goHome">
			<div class="header-image hidden-xs-only">
				<v-img
					alt="Logo"
					class="mr-2"
					src="@/assets/owo-header.png"
					transition="scale-transition"
					width="220"
				/>
			</div>
			<span class="nav-title mt-1 text-h4 hidden-sm-and-down"> OwO Bot </span>
		</div>

		<v-spacer></v-spacer>

		<v-tabs class="nav-tabs" v-model="tab" right dark color="secondary">
			<v-tab v-for="item in items" :key="item.to" :to="item.to">
				{{ item.text }}
			</v-tab>
		</v-tabs>
		<DiscordDropdown />
	</v-app-bar>
</template>

<script>
import endpoints from '@/router/routes.js';
import DiscordDropdown from '@/components/discord/DiscordDropdown.vue';

export default {
	name: 'NavbarHeader',
	components: {
		DiscordDropdown,
	},
	data: () => ({
		tab: null,
		items: [
			{
				text: 'Home',
				to: endpoints.HOME,
			},
			{
				text: 'Help',
				to: endpoints.HELP,
			},
			{
				text: 'Store',
				to: endpoints.STORE,
			},
		],
	}),
	methods: {
		goHome() {
			this.$router.push('/');
		},
	},
};
</script>
<style scoped>
.nav-title {
	width: max-content;
}

.nav-tabs {
	margin-right: 20px;
}

.navbar-height {
	max-height: calc(100% + 8px);
	min-width: max-content;
	overflow-y: hidden;
	cursor: pointer;
}
</style>
