// import axios from 'axios'
import Vue from 'vue';
const countMax = 10000;

const getDefaultState = () => {
	return {
		items: {},
		giftTo: null,
	};
};

const state = getDefaultState();

const getters = {
	cart: (state) => state.items,
	totalCost: (state) => {
		return Object.values(state.items).reduce(
			(acc, curr) => acc + curr.price * curr.count,
			0
		);
	},
	giftTo: (state) => state.giftTo,
};

const actions = {
	addToCart: ({ commit }, { item, count }) => {
		commit('addItem', { item, count });
	},
	setItemCount: ({ commit }, itemInfo) => {
		commit('setItemCount', itemInfo);
	},
	removeFromCart: ({ commit }, item) => {
		commit('removeItem', item.id);
	},
	clearCart: ({ commit }) => {
		commit('clearCart');
	},
	setGiftTo: ({ commit }, giftTo) => {
		commit('setGiftTo', giftTo);
	},
	clearGiftTo: ({ commit }) => {
		commit('clearGiftTo');
	},
};

const mutations = {
	addItem: (state, { item, count }) => {
		if (state.items[item.id]) {
			const updatedItem = state.items[item.id];
			updatedItem.count += count;
			if (updatedItem.count > countMax) updatedItem.count = countMax;
			Vue.set(state.items, item.id, updatedItem);
		} else {
			const newItem = { ...item };
			newItem.count = count;
			if (newItem.count > countMax) newItem.count = countMax;
			Vue.set(state.items, item.id, newItem);
		}
	},
	removeItem: (state, itemId) => {
		Vue.delete(state.items, itemId);
	},
	setItemCount: (state, { item, count }) => {
		if (state.items[item.id]) {
			const updatedItem = state.items[item.id];
			updatedItem.count = count;
			if (updatedItem.count > countMax) updatedItem.count = countMax;
			Vue.set(state.items, item.id, updatedItem);
		} else {
			const newItem = { ...item };
			newItem.count = count;
			if (newItem.count > countMax) newItem.count = countMax;
			Vue.set(state.items, item.id, newItem);
		}
	},
	clearCart: (state) => {
		Object.assign(state, getDefaultState());
	},
	setGiftTo: (state, giftTo) => {
		Vue.set(state, 'giftTo', giftTo);
	},
	clearGiftTo: (state) => {
		Vue.delete(state, 'giftTo');
	},
};

export default { state, getters, actions, mutations };
