<template>
	<div
		:class="isMobile ? 'panel-mobile' : 'panel'"
		v-observe-visibility="{
			callback: visible,
			intersection: {
				threshold: isMobile ? 0.95 : 0.55,
			},
			once: true,
			throttle: 300,
		}"
	>
		<v-scroll-y-reverse-transition>
			<div
				v-if="show"
				:class="{
					'panel-contents': !isMobile,
					'panel-contents-mobile': isMobile,
					flip: flip && !isMobile,
				}"
			>
				<slot></slot>
				<div class="padding"></div>
				<div :class="isMobile ? 'panel-text-mobile' : 'panel-text'">
					<div class="body-title text-h3 primary--text">
						{{ title }}
					</div>
					<div class="text-body">
						{{ desc }}
					</div>
				</div>
			</div>
		</v-scroll-y-reverse-transition>
	</div>
</template>

<script>
export default {
	name: 'HomeBody',
	props: {
		title: {
			type: String,
			required: true,
		},
		desc: {
			type: String,
			required: true,
		},
		flip: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	data: () => ({
		show: false,
	}),
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.width < 940;
		},
	},
	methods: {
		visible(isVisible) {
			if (isVisible) this.show = true;
		},
	},
};
</script>

<style scoped>
.panel {
	width: 100%;
	height: 600px;
	padding: 200px 50px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.panel-mobile {
	width: 100%;
	padding: 150px 50px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.panel-contents {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.panel-contents-mobile {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column-reverse;
}

.padding {
	width: 40px;
}

.flip {
	flex-direction: row-reverse;
}

.panel-text {
	max-width: 50%;
}

.panel-text-mobile {
	max-width: 80%;
	padding-bottom: 30px;
}

.body-title {
	font-weight: bold;
}
</style>
