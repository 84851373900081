<template>
	<v-btn
		:width="width"
		color="primary"
		href="https://discord.com/oauth2/authorize?client_id=408785106942164992&permissions=1074120776&scope=bot"
	>
		<v-img
			:src="require('@/assets/discord-logo.svg')"
			class="mr-3"
			contain
			max-height="20"
			max-width="20"
		/>
		Invite me!
	</v-btn>
</template>

<script>
export default {
	name: 'DiscordInvite',
	props: {
		width: {
			type: String,
			default: '300px',
		},
	},
};
</script>

<style scoped></style>
