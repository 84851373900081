import axios from 'axios';
import Vue from 'vue';

const state = {
	catalog: {},
};

const getters = {
	catalog: (state) => state.catalog,
	categories: (state) => Object.keys(state.catalog),
};

const actions = {
	fetchCatalog: async ({ commit }) => {
		let res = await axios.get(
			process.env.VUE_APP_BACKEND + '/api/shop/catalog'
		);
		const catalog = res.data;
		for (let id in catalog) {
			catalog[id].id = id;
		}
		await commit('setCatalog', res.data);
		return catalog;
	},
};

const mutations = {
	setCatalog: (state, catalog) => {
		Vue.set(state, 'catalog', catalog);
	},
};

export default { state, getters, actions, mutations };
