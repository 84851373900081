export default {
	install(Vue) {
		Vue.prototype.getEmojiUrl = (emoji) => {
			if (!emoji) return;
			let emojiMatch = emoji.match(/:[0-9]+>/g) || [];
			let emojiId = emojiMatch[0];
			if (emojiId) {
				emojiMatch = emojiId.match(/[0-9]+/g) || [];
				emojiId = emojiMatch[0];
			}
			if (emojiId && emojiId > 10000) {
				let imgType = 'png';
				if (emoji.match('<a:')) imgType = 'gif';
				return `https://cdn.discordapp.com/emojis/${emojiId}.${imgType}`;
			} else {
				return `https://logs.owobot.com/unicodeanimals/${emoji.replace(
					/:/gi,
					''
				)}.png`;
			}
		};
	},
};
