import axios from 'axios';

const actions = {
	getBattleLog: async (_vue, uuid) => {
		let res;
		try {
			res = await axios.get(
				`${process.env.VUE_APP_BACKEND}/api/battle-log/${uuid}`,
				{ withCredentials: true }
			);
			return res.data;
		} catch (err) {
			console.error(err);
		}
		res = await axios.get(`${process.env.VUE_APP_GEN}/logs/${uuid}`);
		return res.data;
	},
	saveBattleLog: async (_vue, uuid) => {
		let res = await axios.post(
			`${process.env.VUE_APP_BACKEND}/api/battle-log/save`,
			{ uuid },
			{ withCredentials: true }
		);
		return res;
	},
	getSavedBattleLog: async (_vue, { limit, page, global }) => {
		if (global === 'recent') {
			global = '&orderBy=recent';
		} else if (global === 'popular') {
			global = '&orderBy=top';
		} else {
			global = '';
		}
		let res = await axios.get(
			`${process.env.VUE_APP_BACKEND}/api/battle-log?page=${page}&limit=${limit}${global}`,
			{
				withCredentials: true,
			}
		);
		return res.data;
	},
	getTotalSavedLogs: async () => {
		let res = await axios.get(
			`${process.env.VUE_APP_BACKEND}/api/battle-log/total-saved-count`,
			{ withCredentials: true }
		);
		return res.data;
	},
	getTotalLogs: async () => {
		let res = await axios.get(
			`${process.env.VUE_APP_BACKEND}/api/battle-log/total-count`,
			{ withCredentials: true }
		);
		return res.data;
	},
	deleteSavedBattle: async (_vue, uuid) => {
		let res = await axios.post(
			`${process.env.VUE_APP_BACKEND}/api/battle-log/remove`,
			{ uuid },
			{ withCredentials: true }
		);
		return res.data;
	},
};

export default { actions };
