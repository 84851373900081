import Vue from 'vue';
import TheModal from '@/components/TheModal';

export default function (props = {}) {
	const $parent = this;

	return {
		showError: () => {
			props.error = true;
			return showModal($parent, props);
		},
		showInfo: () => {
			props.info = true;
			return showModal($parent, props);
		},
		showWarn: () => {
			props.warn = true;
			return showModal($parent, props);
		},
	};
}

// Create instance of AlertModal component
const showModal = ($parent, props) => {
	const Modal = Vue.extend({
		extends: TheModal,
		// Below destruct handlers replicated from: https://github.com/bootstrap-vue/bootstrap-vue/blob/3828f59e4c640571ad86b4d4851d6ac31eaa9466/src/components/modal/helpers/bv-modal.js
		// License: https://github.com/bootstrap-vue/bootstrap-vue/blob/dev/LICENSE
		destroyed() {
			if (this.$el && this.$el.parentNode) {
				this.$el.parentNode.removeChild(this.$el);
			}
		},
		mounted() {
			// Self destruct handler
			const handleDestroy = () => {
				const self = this;
				this.$nextTick(() => {
					// In a `setTimeout()` to release control back to application
					setTimeout(() => self.$destroy(), 0);
				});
			};
			// Self destruct if parent destroyed
			this.$parent.$once('hook:destroyed', handleDestroy);
			// Self destruct after hidden
			this.$once('hide', handleDestroy);
			// Self destruct on route change
			if (this.$router && this.$route) {
				const unwatch = this.$watch('$router', handleDestroy);
				this.$once('hook:beforeDestroy', unwatch);
			}
		},
	});

	const modal = new Modal({
		parent: $parent,
		extends: Modal,
		propsData: { ...props },
	});

	// Resolve to either `true` for confirmed or `false` for canceled events
	return new Promise((resolve, reject) => {
		modal.$on('confirm', (res) => {
			resolve(res || true);
		});
		modal.$on('cancel', () => {
			resolve(false);
		});
		modal.$on('returns', (returns) => {
			resolve(returns);
		});
		modal.$on('error', (error) => {
			reject(error);
		});

		// Mount our alert modal to the root <body> element
		const div = document.createElement('div');
		document.body.appendChild(div);
		modal.$mount(div);
	});
};
