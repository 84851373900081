<template>
	<vue-particles
		id="tsparticles-confetti"
		:options="options"
	/>
</template>

<script>
import {confetti} from '@tsparticles/confetti';

export default {
	name: 'ParticalConfetti',
	props: {
		quantity: {
			type: Number,
			default: 10,
		},
		duration: {
			type: Number,
			default: 1,
		},
	},
	data: () => ({
		options: {
  "fullScreen": {
    "enable": true,
    "zIndex": 100
  },
  "fpsLimit": 60,
  "particles": {
    "number": {
      "value": 0
    },
    "color": {
					value: [
"#26ccff",
"#a25afd",
"#ff5e7e",
"#88ff5a",
"#fcff42",
"#ffa62d",
"#ff36ff"
			]
    },
    "shape": {
      "type": [
        "square",
        "circle"
      ],
      "options": {}
    },
    "opacity": {
      "value": {
        "min": 0,
        "max": 1
      },
      "animation": {
        "enable": true,
        "sync": true,
        "speed": 0.46296296296296297,
        "startValue": "max",
        "destroy": "min"
      }
    },
    "size": {
					value: { min: 3, max: 7 },
    },
    "links": {
      "enable": false
    },
    "life": {
      "count": 1
    },
    "move": {
      "angle": {
        "value": 45,
        "offset": 0
      },
      "drift": {
        "min": 0,
        "max": 0
      },
      "enable": true,
      "gravity": {
        "enable": true,
        "acceleration": 9.81
      },
      "speed": 135,
      "decay": 0.09999999999999998,
      "direction": -45,
      "random": true,
      "straight": false,
      "outModes": {
        "default": "none",
        "bottom": "destroy"
      }
    },
    "rotate": {
      "value": {
        "min": 0,
        "max": 360
      },
      "direction": "random",
      "animation": {
        "enable": true,
        "speed": 60
      }
    },
    "tilt": {
      "direction": "random",
      "enable": true,
      "value": {
        "min": 0,
        "max": 360
      },
      "animation": {
        "enable": true,
        "speed": 60
      }
    },
    "roll": {
      "darken": {
        "enable": true,
        "value": 25
      },
      "enable": true,
      "speed": {
        "min": 15,
        "max": 25
      }
    },
    "wobble": {
      "distance": 30,
      "enable": true,
      "speed": {
        "min": -15,
        "max": 15
      }
    }
  },
  "detectRetina": true,
  "motion": {
    "disable": true
  },
  "emitters": [
			{
					direction: 'top-right',
					rate: {
						delay: 0.1,
						quantity: 10,
					},
					position: {
						x: 0,
						y: 50,
					},
					size: {
						width: 0,
						height: 0,
					},
					life: {
						count: 1,
						duration: 1,
					},
				},
				{
					direction: 'top-left',
					rate: {
						delay: 0.1,
						quantity: 10,
					},
					position: {
						x: 100,
						y: 50,
					},
					size: {
						width: 0,
						height: 0,
					},
					life: {
						count: 1,
						duration: 1,
					},
				}
  ]
}
	}),
	mounted() {
		this.options.emitters.forEach((emitter) => {
			emitter.rate.quantity = this.quantity;
			emitter.life.duration = this.duration;
		});
	},
};
</script>

<style scoped>
#tsparticles {
	position: fixed;
	z-index: 9999;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	margin: 0;
	pointer-events: none;
	touch-action: none;
}

#tsparticles >>> canvas {
	pointer-events: none !important;
	touch-action: none !important;
}
</style>
