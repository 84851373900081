import Vue from 'vue';
import axios from 'axios';
import {
	getCommands,
	setDefaultFields,
	setRequiredFields
} from '@/views/customize/utils/CustomizeUtil.js';

const getDefaultState = () => {
	return {
		showDrawer: false,
		selectedForm: {},
		customizeUpdated: false,
		customizeLoaded: false,
		commands: getCommands(),
	};
};

const state = getDefaultState();

const getters = {
	commands: (state) => state.commands,
	selectedForm: (state) => state.selectedForm,
	findCommand: (state) => (commandName) => {
		return state.commands.find((command) => command.link === commandName);
	},
	showDrawer: (state) => state.showDrawer,
	customizeLoaded: (state) => state.customizeLoaded,
};

const actions = {
	getCommands: async ({ commit }) => {
		let res = await axios.get(`${process.env.VUE_APP_BACKEND}/api/customize`, {
			withCredentials: true,
		});

		for (let command in res.data) {
			const forms = { ...res.data[command] };
			const required = forms.required;
			delete forms.required;
			setRequiredFields(command, required);
			for (let type in forms) {
				const form = forms[type];
				setDefaultFields(command, type, form);
			}
		}
		await commit('resetForms');
		await commit('setForms', res.data);
	},
	setSelected: async ({ commit }, form) => {
		await commit('setSelected', form);
	},
	updateSelectedForm: async ({ commit }, { field, value }) => {
		await commit('updateSelected', { field, value });
	},
	updateDrawer: async ({ commit }, value) => {
		await commit('updateDrawer', value);
	},
	setCustomizedCommand: async ({ rootGetters }, command) => {
		let res = await axios.post(
			`${process.env.VUE_APP_BACKEND}/api/customize/${command}`,
			{ form: rootGetters.selectedForm },
			{ withCredentials: true }
		);
		return res.data;
	},
};

const mutations = {
	setForms: (state, forms) => {
		for (let type in forms) {
			const command = state.commands.find((command) => command.link == type);
			if (Object.keys(forms[type]).length) {
				Vue.set(command, 'forms', forms[type]);
				Vue.set(command, 'enabled', true);
			}
		}
		state.customizeLoaded = true;
	},
	setSelected: (state, form) => {
		Vue.set(state, 'selectedForm', form);
		state.customizeUpdated = false;
	},
	updateSelected: (state, { field, value }) => {
		Vue.set(state.selectedForm, field, value);
		state.customizeUpdated = true;
	},
	updateDrawer: (state, value) => {
		Vue.set(state, 'showDrawer', value);
	},
	resetForms: (state) => {
		state.customizeLoaded = false;
		for (let i in state.commands) {
			const command = state.commands[i];
			Vue.set(command, 'enabled', false);
			Vue.set(command, 'forms', {});
		}
	},
};

export default { state, getters, actions, mutations };
