<template>
	<v-snackbar v-model="snackbar" timeout="-1" class="snackbar">
		We use cookies to ensure you get the best experience on our website. Nom.
		<template v-slot:action="{ attrs }">
			<v-btn v-bind="attrs" color="secondary" text @click="acceptCookies">
				Accept
			</v-btn>
		</template>
	</v-snackbar>
</template>

<script>
export default {
	name: 'CookieSnackbar',
	data: () => ({
		snackbar: false,
	}),
	methods: {
		acceptCookies() {
			this.$store.dispatch('acceptCookie');
			this.snackbar = false;
		},
	},
	created() {
		if (!this.$store.getters.cookieAccepted) {
			this.snackbar = true;
		}
	},
};
</script>

<style scoped lang="scss">
.snackbar {
	::v-deep .v-snack__wrapper {
		border: 3px solid var(--v-secondary-base) !important;
	}
}
</style>
