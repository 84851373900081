import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
	theme: {
		options: {
			customProperties: true,
		},
		dark: true,
		themes: {
			dark: {
				primary: '#7DBCEB',
				secondary: '#FFDA00',
				accent: '#FFA000',
				success: '#90EE90',
				background: '#36393F',
				background_dark: '#2C2F33',
				background_dark2: '#1e1e1e',
			},
		},
	},
});
