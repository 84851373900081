import axios from 'axios';
import Vue from 'vue';

const state = {
	pendingEmotes: [],
	pendingEmotesMap: {},
};

const getters = {
	pendingEmotes: (state) => state.pendingEmotes,
};

const actions = {
	uploadNewEmote: (v, { file, category }) => {
		const categoryBlob = new Blob([category], { type: 'text/plain' });

		const form = new FormData();
		form.append('category', categoryBlob);
		form.append('image', file);
		return axios.post(process.env.VUE_APP_BACKEND + '/api/emote/upload', form, {
			headers: { 'Content-Type': 'multipart/form-data' },
			withCredentials: true,
		});
	},
	getEmoteCategories: async () => {
		const res = await axios.get(
			process.env.VUE_APP_BACKEND + '/api/emote/categories',
			{
				withCredentials: true,
			}
		);
		return res.data;
	},
	getPendingEmotes: async ({ commit }, { page, sortBy, order = 'desc' }) => {
		const res = await axios.get(
			`${process.env.VUE_APP_BACKEND}/api/emote/pending?page=${page}&limit=30&sort-by=${sortBy}&order-by=${order}`,
			{
				withCredentials: true,
			}
		);
		await commit('appendPendingEmotes', res.data);
		return res.data;
	},
	clearPendingEmotes: ({ commit }) => {
		commit('clearPendingEmotes');
	},
	likeEmote: (v, emoteId) => {
		return axios.patch(
			`${process.env.VUE_APP_BACKEND}/api/emote/like/${emoteId}`,
			{},
			{
				withCredentials: true,
			}
		);
	},
	dislikeEmote: (v, emoteId) => {
		return axios.patch(
			`${process.env.VUE_APP_BACKEND}/api/emote/dislike/${emoteId}`,
			{},
			{
				withCredentials: true,
			}
		);
	},
	approveEmote: async ({ commit }, emoteId) => {
		await axios.post(
			`${process.env.VUE_APP_BACKEND}/api/emote/approve/${emoteId}`,
			{},
			{
				withCredentials: true,
			}
		);
		commit('removeEmote', emoteId);
	},
	banEmote: async ({ commit }, emoteId) => {
		await axios.post(
			`${process.env.VUE_APP_BACKEND}/api/emote/ban/${emoteId}`,
			{},
			{
				withCredentials: true,
			}
		);
		commit('removeEmote', emoteId);
	},
	deleteEmote: async ({ commit }, emoteId) => {
		await axios.post(
			`${process.env.VUE_APP_BACKEND}/api/emote/delete/${emoteId}`,
			{},
			{
				withCredentials: true,
			}
		);
		commit('removeEmote', emoteId);
	},
};

const mutations = {
	appendPendingEmotes: (state, emotes) => {
		emotes.forEach((emote) => {
			if (state.pendingEmotesMap[emote._id]) {
				Object.keys(emote).forEach((key) => {
					Vue.set(state.pendingEmotesMap[emote._id], key, emote[key]);
				});
			} else {
				state.pendingEmotesMap[emote._id] = emote;
				state.pendingEmotes.push(emote);
			}
		});
	},
	addPendingEmote: (state, emote) => {
		if (state.pendingEmotesMap[emote._id]) {
			console.log('duplicate');
			Object.keys(emote).forEach((key) => {
				Vue.set(state.pendingEmotesMap[emote._id], key, emote[key]);
			});
		} else {
			state.pendingEmotesMap[emote._id] = emote;
			state.pendingEmotes.unshift(emote);
		}
	},
	removeEmote: (state, emoteId) => {
		if (!state.pendingEmotesMap[emoteId]) return;
		const index = state.pendingEmotes.findIndex(
			(emote) => emote._id === emoteId
		);
		Vue.delete(state.pendingEmotesMap, emoteId);
		Vue.delete(state.pendingEmotes, index);
	},
	clearPendingEmotes: (state) => {
		state.pendingEmotes = [];
		state.pendingEmotesMap = {};
	},
};

export default { state, getters, actions, mutations };
