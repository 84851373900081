<template>
	<div class="form-row">
		<v-card-title>Embedded Color</v-card-title>
		<v-menu
			v-model="colorModal"
			offset-y
			:close-on-content-click="false"
			attach
			nudge-bottom="10"
			left
			nudge-right="68"
		>
			<template v-slot:activator="{ on, attrs }">
				<v-btn
					class="color-button"
					depressed
					:color="color"
					v-bind="attrs"
					v-on="on"
				></v-btn>
			</template>
			<v-color-picker v-model="color"></v-color-picker>
		</v-menu>
	</div>
</template>

<script>
const field = 'color';

export default {
	name: 'FormColor',
	data: () => ({
		colorModal: false,
	}),
	computed: {
		color: {
			get() {
				return this.intToHex(this.$store.getters.selectedForm.color);
			},
			set(value) {
				this.$store.dispatch('updateSelectedForm', {
					field,
					value: this.hexToInt(value),
				});
			},
		},
	},
	methods: {
		hexToInt(hex) {
			return parseInt(hex.slice(1, 7), 16);
		},
		intToHex(integer) {
			if (!integer) return '#FFFFFF';
			return '#' + integer.toString(16).padStart(6, '0');
		},
	},
};
</script>

<style scoped>
.form-row {
	display: flex;
	align-items: center;
}

.color-button {
	border: 2px solid white !important;
}
</style>
