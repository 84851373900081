<template>
	<div class="form-row">
		<v-card-title>Show Avatar</v-card-title>
		<v-switch
			:disabled="disabled"
			v-model="showAvatar"
			color="primary"
		></v-switch>
	</div>
</template>

<script>
const field = 'showAvatar';

export default {
	name: 'FormEmbed',
	computed: {
		showAvatar: {
			get() {
				return this.$store.getters.selectedForm[field];
			},
			set(value) {
				this.$store.dispatch('updateSelectedForm', { field, value });
			},
		},
		disabled() {
			return !this.$store.getters.selectedForm['author'];
		},
	},
};
</script>

<style scoped>
.form-row {
	display: flex;
	align-items: center;
}
</style>
