<template>
	<div class="form-row">
		<v-card-title>Embedded?</v-card-title>
		<v-switch v-model="isEmbed" color="primary"></v-switch>
	</div>
</template>

<script>
const field = 'isEmbed';

export default {
	name: 'FormEmbed',
	computed: {
		isEmbed: {
			get() {
				return this.$store.getters.selectedForm[field];
			},
			set(value) {
				this.$store.dispatch('updateSelectedForm', { field, value });
			},
		},
	},
};
</script>

<style scoped>
.form-row {
	display: flex;
	align-items: center;
}
</style>
