<template>
	<div :class="isMobile ? 'header-mobile' : 'header'">
		<ParticleBackground />
		<div :class="{ 'prevent-click': isMobile }"></div>
		<div :class="isMobile ? 'contents-mobile' : 'contents'" class="noselect">
			<div :class="isMobile ? 'information-mobile' : 'information'">
				<span :class="isMobile ? 'invite-mobile' : 'invite'" class="text-h1">
					<span class="back-text" style="z-index: -1">OwO! What's this?</span>
					OwO! What's this?
				</span>
				<span
					class="text-subtitle-1"
					:class="isMobile ? 'desc-mobile' : 'desc'"
				>
					Hewwo! I'm OwO Bot! A Discord Bot that can make your server fun and
					interactive! Hunt, battle, and gamble your way to the top of the
					leaderboards.
				</span>
				<DiscordInvite class="mt-6" :width="isMobile ? '100%' : '300px'" />
			</div>
			<v-img
				:class="isMobile ? 'peek-mobile' : 'peek'"
				contain
				src="@/assets/owo-peek.png"
			></v-img>
		</div>
	</div>
</template>

<script>
import ParticleBackground from '@/components/particles/ParticleBackground';
import DiscordInvite from '@/components/DiscordInvite';

export default {
	name: 'HomeHeader',
	components: {
		ParticleBackground,
		DiscordInvite,
	},
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.width < 940;
		},
	},
};
</script>

<style scoped>
.header {
	position: relative;
	width: 100%;
	height: 400px;
	background-color: rgba(0, 0, 0, 0.5);
}

.header-mobile {
	position: relative;
	width: 100%;
	min-height: 100%;
	height: min-content;
	background-color: rgba(0, 0, 0, 0.5);
}

.contents {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: space-between;
}

.contents-mobile {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.peek {
	max-width: 30vw;
	align-self: flex-end;
	margin-right: 2vw;
}

.peek-mobile {
	width: 100%;
	align-self: flex-end;
	max-height: 250px;
	margin-top: 100px;
}

.information {
	align-self: center;
	margin-left: 5%;
	display: flex;
	flex-direction: column;
}

.information-mobile {
	align-self: center;
	margin-left: 5%;
	margin-right: 5%;
	display: flex;
	flex-direction: column;
}

.invite {
	color: var(--v-primary-base);
	white-space: nowrap;
	font-size: 5vw !important;
	line-height: 6.5vw;
	font-weight: 500;
	position: relative;
	z-index: 0;
}

.invite-mobile {
	margin-top: 40px;
	color: var(--v-primary-base);
	font-size: 5.5rem !important;
	line-height: 5.5rem;
	font-weight: 500;
	position: relative;
	z-index: 0;
}

.desc {
	width: 68%;
	font-size: 1.2vw !important;
	line-height: 2vw;
}

.desc-mobile {
	margin-top: 13px;
	margin-bottom: 30px;
	font-size: 1.5rem !important;
	line-height: 2rem;
}

.back-text {
	position: absolute;
	color: var(--v-secondary-base);
	left: -0.14vw;
	bottom: -0.2vw;
}

.prevent-click {
	position: absolute;
	z-index: 0;
	width: 100%;
	height: 100%;
	pointer-events: all;
}
</style>
