import axios from 'axios';

const actions = {
	verifyCaptcha: (vue, token) => {
		return axios.post(
			`${process.env.VUE_APP_BACKEND}/api/captcha/verify`,
			{ token },
			{ withCredentials: true }
		);
	},
};

export default { actions };
