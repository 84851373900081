<template>
	<div class="form-row">
		<v-card-title>Rank Emojis</v-card-title>
		<div class="rank-row" v-for="{ id, url } in ranks" :key="id">
			<v-img
				contain
				max-width="40"
				height="40"
				:src="url"
				class="rank-emoji mb-3"
			></v-img>
			<v-text-field
				class="pt-3 pr-3"
				:rules="rules"
				v-model="result[id]"
				@change="updateRank(id, $event)"
				label="Emoji Id"
				counter="256"
				width="100%"
				dense
				outlined
			></v-text-field>
		</div>
	</div>
</template>

<script>
const field = 'extra';

export default {
	name: 'FormZooRanks',
	data: () => ({
		rules: [
			(v) => !v || v.length <= 256 || 'Footer must be under 256 characters',
			(v) => !v || (v.match(/\n/g)?.length || 0) < 6 || 'Maximum of 6 lines',
			(v) => !v || !v.match(/<@!?&?\d+>/gi) || "You can't tag a user or role",
			(v) =>
				!v ||
				!v.match(
					/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/gi
				) ||
				"You can't include a link",
			(v) =>
				!v || !v.match(/discord\.gg/gi) || "You can't include an invite link",
		],
		ranks: [
			{
				id: 'common',
				url: 'https://cdn.discordapp.com/emojis/416520037713838081.png'
			},
			{
				id: 'uncommon',
				url: 'https://cdn.discordapp.com/emojis/416520056269176842.png'
			},
			{
				id: 'rare',
				url: 'https://cdn.discordapp.com/emojis/416520066629107712.png'
			},
			{
				id: 'epic',
				url: 'https://cdn.discordapp.com/emojis/416520722987614208.png'
			},
			{
				id: 'patreon',
				url: 'https://cdn.discordapp.com/emojis/449705754522419222.png'
			},
			{
				id: 'special',
				url: 'https://cdn.discordapp.com/emojis/427935192137859073.png'
			},
			{
				id: 'mythical',
				url: 'https://cdn.discordapp.com/emojis/416520808501084162.png'
			},
			{
				id: 'gem',
				url: 'https://cdn.discordapp.com/emojis/510023576489951232.gif'
			},
			{
				id: 'legendary',
				url: 'https://cdn.discordapp.com/emojis/417955061801680909.gif'
			},
			{
				id: 'bot',
				url: 'https://cdn.discordapp.com/emojis/716545289497870407.gif'
			},
			{
				id: 'distorted',
				url: 'https://cdn.discordapp.com/emojis/728812986147274835.gif'
			},
			{
				id: 'cpatreon',
				url: 'https://cdn.discordapp.com/emojis/483053960337293321.gif'
			},
			{
				id: 'fabled',
				url: 'https://cdn.discordapp.com/emojis/438857004493307907.gif'
			},
			{
				id: 'hidden',
				url: 'https://cdn.discordapp.com/emojis/459203677438083074.gif'
			},
		],
		tempResult: {}
	}),
	computed: {
		result: {
			get() {
				this.tempResult = this.$store.getters.selectedForm[field] || {};
				return this.tempResult;
			},
		}
	},
	methods: {
		updateRank(id, value) {
			this.tempResult[id] = value;

			this.$store.dispatch('updateSelectedForm', { field, value: this.tempResult });
		}
	}
};
</script>

<style scoped>
.form-row {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	width: 100%;
}

.rank-row {
	display: flex;
	align-items: center;
	width: 100%;
}

.rank-emoji {
	margin-right: 16px;
	margin-left: 16px;
}
</style>
