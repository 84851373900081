<template>
	<v-app
		:style="{ background: $vuetify.theme.themes['dark'].background }"
		class="app"
	>
		<NavbarHeader v-if="!hideHeader" />
		<v-main class="main">
			<div class="router">
				<router-view></router-view>
			</div>
			<TheFooter v-if="!hideFooter" />
		</v-main>
	</v-app>
</template>

<script>
import NavbarHeader from '@/components/NavbarHeader';
import TheFooter from '@/components/TheFooter';

export default {
	name: 'App',
	components: {
		NavbarHeader,
		TheFooter,
	},
	computed: {
		hideHeader() {
			return this.$route.meta?.hideHeader;
		},
		hideFooter() {
			return this.$route.meta?.hideFooter;
		},
		pageOverflow() {
			return 'auto';
		},
	},
	watch: {
		$route() {
			window.scrollTo(0, 0);
		},
	},
};
</script>

<style>
html {
	overflow: auto;
}

body {
	width: 100%;
	height: 100vh;
}

.router {
	position: relative;
	min-height: 100vh;
}

.noselect {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

html::-webkit-scrollbar {
	width: 15px;
}

html::-webkit-scrollbar-track {
	background: var(--v-background_dark2-base);
	border-left: 1px solid var(--v-background-base);
}

html::-webkit-scrollbar-thumb {
	background: var(--v-background-base);
	border: solid 3px var(--v-background_dark2-base);
	border-radius: 7px;
}

html::-webkit-scrollbar-thumb:hover {
	background: var(--v-secondary-base);
}

html::-webkit-scrollbar {
	width: 15px;
}

.scroll::-webkit-scrollbar-track {
	background: var(--v-background_dark2-base);
	border-left: 1px solid var(--v-background-base);
}

.scroll::-webkit-scrollbar-thumb {
	background: var(--v-background-base);
	border: solid 3px var(--v-background_dark2-base);
	border-radius: 7px;
}

.scroll::-webkit-scrollbar-thumb:hover {
	background: var(--v-secondary-base);
}
</style>
