<template>
	<div class="form-row">
		<v-card-title>Message Text</v-card-title>
		<v-card-subtitle
			>This is replaces the default message OwO bot sends you! You can use the
			provided variables to alter your message. The message visualizer does not
			support markdown (italics, bold, etc), but will be displayed on Discord's
			messages.</v-card-subtitle
		>
		<div class="px-4 body-textarea">
			<v-textarea
				v-model="body"
				:rules="rules"
				outlined
				width="500"
				auto-grow
				dense
				no-resize
				counter="512"
				label="Type your new message here!"
			></v-textarea>
		</div>
	</div>
</template>

<script>
const field = 'text';

export default {
	name: 'FormBody',
	data: () => ({
		rules: [
			(v) => !v || v.length <= 512 || 'Body must be under 512 characters',
			(v) => !v || (v.match(/\n/g)?.length || 0) < 6 || 'Maximum of 6 lines',
			(v) => !v || !v.match(/<@!?&?\d+>/gi) || "You can't tag a user or role",
			(v) =>
				!v ||
				!v.match(
					/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/gi
				) ||
				"You can't include a link",
			(v) =>
				!v || !v.match(/discord\.gg/gi) || "You can't include an invite link",
		],
	}),
	computed: {
		body: {
			get() {
				return this.$store.getters.selectedForm[field];
			},
			set(value) {
				this.$store.dispatch('updateSelectedForm', { field, value });
			},
		},
	},
};
</script>

<style scoped>
.form-row {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.body-textarea {
	width: 100%;
}
</style>
