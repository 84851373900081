<template>
	<div>
		<Confetti v-if="confetti" :quantity="20" :duration="1" />
		<v-dialog
			v-model="show"
			width="min-content"
			height="min-content"
			:persistent="persistent"
			@click:outside="clickedOutside"
		>
			<v-card
				color="background"
				:width="width"
				:class="className"
				:style="style"
			>
				<v-img
					v-if="imgUrl"
					contain
					width="120"
					:src="require(`@/assets/` + imgUrl)"
					class="mb-3"
				></v-img>
				<v-card-text class="modal-text">
					<span v-html="text"></span>
				</v-card-text>
				<v-progress-linear
					v-if="loading"
					color="secondary"
					class="mb-4 progress-bar"
					height="7"
					rounded
					indeterminate
				></v-progress-linear>
				<v-img
					v-if="bottomImgUrl"
					contain
					height="200"
					:src="bottomImgUrl"
					class="mb-3"
				></v-img>
				<v-card-text v-if="bottomText" class="modal-text">
					<span v-html="bottomText"></span>
				</v-card-text>
				<v-card-actions class="actions" v-if="buttons">
					<v-spacer></v-spacer>
					<v-btn
						v-for="(button, index) in buttons"
						class="mb-3"
						:key="index"
						:color="button.color"
						depressed
						:outlined="button.outlined"
						@click="clicked(button.returns)"
					>
						{{ getButtonText(button) }}
					</v-btn>
					<v-spacer v-if="centerButtons"></v-spacer>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import Confetti from '@/components/particles/ParticleConfetti.vue';

export default {
	name: 'TheModal',
	components: {
		Confetti,
	},
	props: {
		text: {
			type: String,
			required: true,
		},
		persistent: {
			type: Boolean,
			required: false,
			default: false,
		},
		imgUrl: {
			type: String,
			required: false,
			default: null,
		},
		bottomImgUrl: {
			type: String,
			required: false,
			default: null,
		},
		bottomText: {
			type: String,
			required: false,
			default: null,
		},
		error: {
			type: Boolean,
			required: false,
			default: false,
		},
		warn: {
			type: Boolean,
			required: false,
			default: false,
		},
		info: {
			type: Boolean,
			required: false,
			default: false,
		},
		width: {
			type: Number,
			required: false,
			default: 300,
		},
		buttons: {
			type: Array,
			required: false,
			default: null,
		},
		centerButtons: {
			type: Boolean,
			required: false,
			default: false,
		},
		wait: {
			type: Promise,
			required: false,
			default: null,
		},
		loading: {
			type: Boolean,
			required: false,
			default: false,
		},
		confetti: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	data: () => ({
		show: true,
		yesText: [
			'Woo Hoo!',
			'🎉',
			'Awesome!',
			'Nice!',
			'OwO',
			'Cool',
			'👌',
			'Oki',
			'Ok 👌',
			'Sounds good!',
			'Yay!',
		],
	}),
	computed: {
		style() {
			if (this.error) {
				return { 'border-color': 'var(--v-error-base) !important' };
			} else if (this.warn) {
				return { 'border-color': 'var(--v-secondary-base) !important' };
			} else {
				return { 'border-color': 'var(--v-primary-base) !important' };
			}
		},
		className() {
			if (this.error) {
				return 'error-card pt-4';
			} else if (this.warn) {
				return 'warn-card pt-4';
			} else {
				return 'info-card pt-4';
			}
		},
	},
	methods: {
		cancel() {
			this.show = false;
			this.$emit('cancel');
			this.$emit('hide');
		},
		confirm(res) {
			this.show = false;
			this.$emit('confirm', res);
			this.$emit('hide');
		},
		clicked(returns) {
			if (returns === undefined) {
				this.confirm();
			} else {
				this.$emit('returns', returns);
				this.$emit('hide');
			}
		},
		clickedOutside() {
			if (!this.persistent) {
				this.cancel();
			}
		},
		async waitForPromise() {
			try {
				const res = await this.wait;
				this.confirm(res);
			} catch (err) {
				this.$emit('error', err);
				this.$emit('hide');
			}
		},
		getButtonText(button) {
			if (button.randomYesText) {
				return this.yesText[Math.floor(Math.random() * this.yesText.length)];
			} else {
				return button.text;
			}
		},
	},
	mounted() {
		if (this.wait) {
			this.waitForPromise();
		}
	},
};
</script>

<style scoped>
.error-card {
	display: flex;
	align-items: center;
	flex-direction: column;
	border: 3px solid var(--v-error-base);
}

.warn-card {
	display: flex;
	align-items: center;
	flex-direction: column;
	border: 3px solid var(--v-secondary-base);
}

.info-card {
	display: flex;
	align-items: center;
	flex-direction: column;
	border: 3px solid var(--v-primary-base);
}

.modal-text {
	color: white !important;
	text-align: center;
	font-size: 1rem;
	padding-bottom: 10px !important;
}

.progress-bar {
	width: 200px;
}

.actions {
	width: 100%;
}

.progress-bar {
	width: calc(100% - 48px);
}
</style>
