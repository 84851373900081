import store from '@/store';
import HuntForm from '@/views/customize/components/commands/HuntForm';
import BattleForm from '@/views/customize/components/commands/BattleForm';
import GiveForm from '@/views/customize/components/commands/GiveForm';
import CowoncyForm from '@/views/customize/components/commands/CowoncyForm';
import EmbedOnlyForm from '@/views/customize/components/commands/EmbedOnlyForm';
import ZooEmbedOnlyForm from '@/views/customize/components/commands/ZooEmbedOnlyForm';
import ZooBasicForm from '@/views/customize/components/commands/ZooBasicForm';
import customize from '@/views/customize/data/customize.json';

function getTab(tab) {
	const tabs = customize[tab]?.tabs;
	if (!tabs) {
		throw `Invalid tab in getTab: ${tab}`;
	}

	return tabs.map((tab) => {
		let component;
		switch (tab.formType) {
			case 'battleform':
				component = BattleForm;
				break;
			case 'basic':
				component = HuntForm;
				break;
			case 'embedonly':
				component = EmbedOnlyForm;
				break;
			case 'zoopaged':
				component = ZooEmbedOnlyForm;
				break;
			case 'zoomessage':
				component = ZooBasicForm;
				break;
			default:
				component = HuntForm
				break;
		}
		return {
			name: tab.name,
			id: tab.id,
			component
		}
	});
}

function getCommands() {
	const result = [];
	for (let key in customize) {
		result.push({
			title: customize[key].name,
			link: customize[key].link,
			icon: customize[key].icon,
			forms: {}
		});
	}
	return result;
}

function setDefaultFields(command, type, form) {
	const tabs = customize[command]?.tabs;
	if (!tabs) {
		throw `Invalid tab command: ${command}`;
	}
	const tab = tabs.find(tab => tab.id === type);
	if (!tab) {
		throw `Invalid tab type: ${type}`;
	}

	const defaultForm = tab.form

	if (form.text) {
		return form;
	}

	for (let key in defaultForm) {
		form[key] = defaultForm[key];
	}

	return form;
}

function replaceText(command, {type, extra} = {}, text) {
	const replacer = getVariables(command, type);
	for (let i in replacer) {
		const variable = replacer[i];
		const rx = RegExp(`{\\s*${variable.name}\\s*}`, 'gi');
		text = text.replace(rx, variable.example);
	}
	if (command === 'zoo') {
		const ranks = [
			{
				emoji: '<:common:416520037713838081>',
				id: 'common'
			},
			{
				emoji: '<:uncommon:416520056269176842>',
				id: 'uncommon'
			},
			{
				emoji: '<:rare:416520066629107712>',
				id: 'rare'
			},
			{
				emoji: '<:epic:416520722987614208>',
				id: 'epic'
			},
			{
				emoji: '<:mythic:416520808501084162>',
				id: 'mythic'
			},
		];

		ranks.forEach((rank) => {
			const extraReplace = extra[rank.id];
			if (extraReplace) {
				text = text.replace(rank.emoji, extraReplace);
			}
		});
	}
	return text;
}

function getVariables(command, type) {
	const tabs = customize[command]?.tabs;
	if (!tabs) {
		throw `Invalid tab: ${command}`;
	}
	const tab = tabs.find(tab => tab.id === type);
	if (!tab) {
		throw `Invalid tab type: ${type}`;
	}
	const variables = [...tab.variables];
	for (let i in variables) {
		const variable = variables[i];
		variable.example = variable.example
				.replaceAll('?username?', store.getters.user.username)
	}

	return variables;
}

function missingVariables(command, form) {
	const variables = getVariables(command, form.type);
	let text = '';
	if (form.isEmbed) {
		for (let i in form) {
			text += form[i] + ',';
		}
	} else {
		text += form.text;
	}
	let missing = {};
	variables.forEach((variable) => {
		const re = RegExp(`{\\s*${variable.name}\\s*}`, 'gi');
		if (variable.required && !re.test(text)) {
			missing[variable.name] = true;
		}
	});
	return missing;
}

function setRequiredFields(command, required) {
	customize[command].tabs.forEach(tab => {
		tab.variables.forEach(variable => {
			if (!required || !required[tab.id]) {
				console.error(`${tab.id} does not exist in ${command}`);
			} else {
				variable.required = required[tab.id].includes(variable.name);
			}
		});
	});
}

export {
	getTab,
	setDefaultFields,
	replaceText,
	getVariables,
	missingVariables,
	getCommands,
	setRequiredFields,
};
