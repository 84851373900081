import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import Particles from '@tsparticles/vue2';
import { loadFull } from "tsparticles";
import VueAnalytics from 'vue-analytics';
import VueDiscordMessage from 'vue-discord-message';
import VueObserveVisibility from 'vue-observe-visibility';
import modal from './plugins/modal';
import util from './plugins/util';

Vue.config.productionTip = false;

Vue.use(VueObserveVisibility);
Vue.use(Particles, {
	init: async engine => {
		await loadFull(engine);
	},
});
Vue.use(VueDiscordMessage, {
	defaultMode: 'cozy',
});
Vue.use(util);

Vue.prototype.$modal = modal;

Vue.use(VueAnalytics, {
	id: 'G-MK5NQJ4ECJ',
	debug: {
		sendHitTask: process.env.NODE_ENV === 'production',
	},
	autoTracking: {
		exception: true,
	},
});

new Vue({
	router,
	store,
	vuetify,
	render: (h) => h(App),
}).$mount('#app');
