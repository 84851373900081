const state = {
	cookieAccepted: false,
	preLoginUrl: '/',
};

const getters = {
	cookieAccepted: (state) => state.cookieAccepted,
	preLoginUrl: (state) => state.preLoginUrl,
};

const actions = {
	acceptCookie: ({ commit }) => {
		commit('setCookie', true);
	},
	setPreLoginUrl: async ({ commit }, url) => {
		await commit('setPreLoginUrl', url);
	},
};

const mutations = {
	setCookie: (state, val) => {
		state.cookieAccepted = val;
	},
	setPreLoginUrl: (state, url) => {
		state.preLoginUrl = url;
	},
};

export default { state, getters, actions, mutations };
